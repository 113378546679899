import React, { useEffect, useState } from "react";

import "./App.css";
import {
  Backdrop,
  CircularProgress,
  ClickAwayListener,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { animated, useSpring } from "react-spring";

import {
  IProviderAnnotation,
  ITraining,
  ITrainingAnnotation,
  ITrainingProvider,
} from "./@types/generated/contentful";
import BreakpointMasonry from "./components/BreakpointMasonry/BreakpointMasonry";
import { FilterSection } from "./components/Filter/FilterSection";
import { Header } from "./components/Header/Header";
import HomeMessage from "./components/HomeMessage/HomeMessage";
import { IntroTour } from "./components/IntroTour/IntroTour";
import TrainingCard from "./components/TrainingCard/TrainingCard";
import {
  getAllProviders,
  // getAllTrainings,
  getContentTypeDescriptor,
  getFilteredTrainings,
  getFirstTypeAnnotations,
} from "./lib/contentful";
import {
  ContentTypeDescriptor,
  IFilterDescriptor,
  ITypeAnnotations,
} from "./lib/types";

const layoutStyles = makeStyles((theme) => ({
  filterContainer: {
    position: "fixed",
    width: "100%",
    maxWidth: 500,
    zIndex: theme.zIndex.drawer + 1,
    height: "100%",
    left: 0,
  },
  header: {
    position: "fixed",
    width: "100%",
    zIndex: theme.zIndex.drawer,
  },
  cardContainer: {
    marginTop: 150,
    marginLeft: 25,
    marginRight: 25,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    color: "#fff",
  },
  filterButton: {
    position: "fixed",
    right: 30,
    bottom: 30,
  },
  noTrainingsCaption: {
    paddingLeft: "20px",
    alignSelf: "center",
    color: "#AAAAAA",
    fontSize: "1.8em",
    fontWeight: 700,
  },
}));

export default function App() {
  const classes = layoutStyles();
  const introTourDoneLocalStorageKey = "intro-tour-done";

  const [dataLoading, setDataLoading] = useState(true);
  const [error, setError] = useState<Array<any>>();
  const [trainings, setTrainings] = useState<ITraining[]>([]);
  const [providers, setProviders] = useState<ITrainingProvider[]>([]);
  const [typeAnnotations, setTypeAnnotations] = useState<ITypeAnnotations>({
    provider: {} as IProviderAnnotation,
    training: {} as ITrainingAnnotation,
  } as ITypeAnnotations);
  const [showFilters, setShowFilters] = useState(true);
  const introDoneLocalStorage =
    localStorage.getItem(introTourDoneLocalStorageKey) === "true";
  const [introDone, setIntroDone] = useState(introDoneLocalStorage);
  const [
    contentTypeDescriptor,
    setContentTypeDescriptor,
  ] = useState<ContentTypeDescriptor>({ provider: [], training: [] });
  const [numberOfFiltersApplied, setNumberOfFiltersApplied] = useState<number>(
    0
  );

  const memorizeIntroDone = () => {
    setIntroDone(true);
    localStorage.setItem(introTourDoneLocalStorageKey, "true");
  };

  useEffect(() => {
    async function persist() {
      //TODO error handling for descriptors
      const descriptorRequest = await getContentTypeDescriptor();
      setContentTypeDescriptor(descriptorRequest);
      const typeAnnotationsRequest = await getFirstTypeAnnotations();
      setTypeAnnotations(typeAnnotationsRequest);
      //TODO order is important use
      //Training Providers
      const providerRequest = await getAllProviders();
      if (providerRequest.errors) {
        setError(providerRequest.errors);
      } else {
        setProviders(providerRequest.items);
      }
      setDataLoading(false);

      //Trainings
      // const trainingRequest = await getAllTrainings();
      // if (trainingRequest.errors) {
      //   setError(trainingRequest.errors);
      // } else {
      //   setTrainings(trainingRequest.items);
      //   setDataLoading(false);
      // }
    }

    persist();
  }, []);

  function countFilters(selection: IFilterDescriptor): number {
    const trainingFilters = Object.values(selection.training)
      .map((v) => v.size)
      .reduce((a, c) => a + c, 0);
    return (
      Object.values(selection.provider)
        .map((v) => v.size)
        .reduce((a, c) => a + c, 0) + trainingFilters
    );
  }

  async function handleFilterChanged(selection: IFilterDescriptor) {
    setNumberOfFiltersApplied(countFilters(selection));
    const filteredTrainings = await getFilteredTrainings(selection);
    if (filteredTrainings.errors) {
      setError(filteredTrainings.errors);
    } else {
      setTrainings(filteredTrainings.items);
    }
  }

  const filterStyleAnim = useSpring({
    transform: !showFilters ? `translate3d(-100%,0,0)` : `translate3d(0%,0,0)`,
  });

  function togglFilters(event?: MouseEvent) {
    setShowFilters(!showFilters);
    event?.preventDefault();
    event?.stopPropagation();
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.header}>
        <Header
          onFilterButtonClick={togglFilters}
          numberOfActiveFilters={numberOfFiltersApplied}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} className={classes.cardContainer}>
        <Backdrop className={classes.backdrop} open={dataLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {!dataLoading && (
          <>
            {error && (
              <HomeMessage>
                An error occurred when fetching the training data
              </HomeMessage>
            )}
            <BreakpointMasonry>
              {trainings.map((training) => (
                <TrainingCard
                  training={training}
                  descriptor={contentTypeDescriptor}
                  provider={
                    providers.find(
                      (x) => x.sys.id === training.fields.provider.sys.id
                    )!
                  }
                  key={training.sys.id}
                />
              ))}
            </BreakpointMasonry>
            {trainings.length === 0 && (
              <HomeMessage>No matching trainings found</HomeMessage>
            )}
          </>
        )}
      </Grid>
      <ClickAwayListener
        onClickAway={() => {
          introDone && setShowFilters(false);
        }}
      >
        <animated.div
          style={filterStyleAnim}
          className={classes.filterContainer}
        >
          <FilterSection
            onFilterClosed={togglFilters}
            contentTypeDescriptor={contentTypeDescriptor}
            onFilterChanged={handleFilterChanged}
            numberOfTrainings={trainings.length}
            typeAnnotations={typeAnnotations}
          />
        </animated.div>
      </ClickAwayListener>
      {!introDone && (
        <IntroTour
          onIntroDone={memorizeIntroDone}
          showFilters={setShowFilters}
        />
      )}
    </Grid>
  );
}
