import React, { Fragment, useCallback, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  ContentTypeDescriptor,
  ContentTypeEntity,
  ContentTypeEntityEnum,
  IFilterDescriptor,
  IFilterFields,
  ITypeAnnotations,
} from "../../lib/types";

import FilterItem from "./FilterItem";

export interface IFilterSectionProps {
  contentTypeDescriptor: ContentTypeDescriptor;
  numberOfTrainings: number;
  typeAnnotations: ITypeAnnotations;

  onFilterChanged(filter: IFilterDescriptor): void;

  onFilterClosed(): void;
}

const filterHandleStyles = makeStyles({
  button: {
    width: "100%",
  },
  titleRow: {
    borderStyle: "hidden",
  },
  container: {
    position: "relative",
    height: "100%",
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(180deg, #F2F7FF 0%, #BED8FE 300%)",
    alignContent: "flex-start",
  },
  tableContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "90%",
  },
  filterHeader: {
    display: "flex",
    height: "70px",
  },
  filterHeaderCaption: {
    paddingLeft: "20px",
    alignSelf: "center",
    color: "#082753",
    fontSize: "1.8em",
    fontWeight: 700,
  },
  filterSubHeader: {
    marginTop: -10,
    paddingLeft: "20px",
  },
  section: {
    border: "none",
  },
  moreFilters: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    "&::before": {
      content: "none",
    },
  },
});
export const FilterSection = ({
  contentTypeDescriptor,
  onFilterChanged,
  onFilterClosed,
  numberOfTrainings,
  typeAnnotations,
}: IFilterSectionProps): JSX.Element => {
  const classes = filterHandleStyles();

  const [selection, setSelection] = useState<IFilterDescriptor>({
    provider: {},
    training: {},
  });

  const handleClick = (type: ContentTypeEntity, id: string, value: string) => {
    const s = selection[type][id];
    if (!s) {
      selection[type][id] = new Set<string>();
    }
    if (selection[type][id].has(value)) {
      selection[type][id].delete(value);
    } else {
      selection[type][id].add(value);
    }
    setSelection({
      training: selection.training,
      provider: selection.provider,
    });
    onFilterChanged(selection);
  };

  const handleClearAll = () => {
    const s = {
      training: {} as IFilterFields,
      provider: {} as IFilterFields,
    };
    setSelection(s);
    onFilterChanged(s);
  };
  const isCategoryAlwaysVisible = (categoryId: string): boolean =>
    ["audienceType", "focus", "format", "language"].includes(categoryId);

  const trainingAnnotationMap = useCallback(() => {
    return typeAnnotations.training?.fields
      ? new Map<string, string>(Object.entries(typeAnnotations.training.fields))
      : new Map<string, string>();
  }, [typeAnnotations.training]);
  const providerAnnotationMap = useCallback(() => {
    return typeAnnotations.training?.fields
      ? new Map<string, string>(Object.entries(typeAnnotations.provider.fields))
      : new Map<string, string>();
  }, [typeAnnotations.provider]);

  return (
    <>
      <Paper className={classes.container} id="filters">
        <Grid container className={classes.container}>
          <Grid item className={classes.filterHeader} xs={12}>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h6" className={classes.filterHeaderCaption}>
                Filter Trainings
              </Typography>
              <Typography className={classes.filterSubHeader}>
                {`${numberOfTrainings} ${
                  numberOfTrainings === 1 ? `training` : `trainings`
                } found`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                title="Clear all filters"
                aria-label="clear filters"
                onClick={handleClearAll}
                id="filters-clear"
              >
                <DeleteSweepIcon color="primary" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                title="Close"
                aria-label="close"
                onClick={onFilterClosed}
                id="filters-close"
              >
                <CloseIcon color={"primary"} />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item className={classes.tableContainer}>
            <Table className={classes.section} id="filters-always-visible">
              <TableBody>
                {contentTypeDescriptor.training.map((field) => (
                  <Fragment key={field.id}>
                    {isCategoryAlwaysVisible(field.id) && (
                      <FilterItem
                        titlePrefix="Training "
                        selectedValues={selection.training}
                        field={field}
                        handleClick={(id, value) =>
                          handleClick(ContentTypeEntityEnum.Training, id, value)
                        }
                        fieldInfoContent={trainingAnnotationMap().get(field.id)}
                      />
                    )}
                  </Fragment>
                ))}
                {contentTypeDescriptor.provider.map((field) => (
                  <Fragment key={field.id}>
                    {isCategoryAlwaysVisible(field.id) && (
                      <FilterItem
                        titlePrefix="Provider "
                        selectedValues={selection.provider}
                        field={field}
                        handleClick={(id, value) =>
                          handleClick(ContentTypeEntityEnum.Provider, id, value)
                        }
                        fieldInfoContent={providerAnnotationMap().get(field.id)}
                      />
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
            <Accordion className={classes.moreFilters} id="filters-more">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>More search options</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table>
                  <TableBody>
                    {contentTypeDescriptor.training.map((field) => (
                      <Fragment key={field.id}>
                        {!isCategoryAlwaysVisible(field.id) && (
                          <FilterItem
                            titlePrefix="Training "
                            selectedValues={selection.training}
                            field={field}
                            handleClick={(id, value) =>
                              handleClick(
                                ContentTypeEntityEnum.Training,
                                id,
                                value
                              )
                            }
                            fieldInfoContent={trainingAnnotationMap().get(
                              field.id
                            )}
                          />
                        )}
                      </Fragment>
                    ))}
                    {contentTypeDescriptor.provider.map((field) => (
                      <Fragment key={field.id}>
                        {!isCategoryAlwaysVisible(field.id) && (
                          <FilterItem
                            titlePrefix="Provider "
                            selectedValues={selection.provider}
                            field={field}
                            handleClick={(id, value) =>
                              handleClick(
                                ContentTypeEntityEnum.Provider,
                                id,
                                value
                              )
                            }
                            fieldInfoContent={providerAnnotationMap().get(
                              field.id
                            )}
                          />
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
