import React from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";

import {
  ITraining,
  ITrainingProvider,
} from "../../@types/generated/contentful";
import { ContentTypeDescriptor } from "../../lib/types";

import ClassificationList from "./ClassificationList";

interface IProps {
  training: ITraining;
  provider: ITrainingProvider;
  descriptor: ContentTypeDescriptor;
}

const cardStyles = makeStyles(() => ({
  card: {
    marginBottom: 20,
    borderTop: "8px solid #0969FA",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  cardTitle: {
    fontSize: "1.5em",
  },
  cardContent: {
    padding: 0,
    paddingTop: 16,
    paddingBottom: 16,
  },
  description: {
    fontSize: 14,
    paddingBottom: 10,
  },
  pos: {
    marginBottom: 12,
  },
  divider: {
    margin: "24px 0",
  },
}));

const TrainingCard = ({ training, provider, descriptor }: IProps) => {
  const classes = cardStyles();

  return (
    <Card className={classes.card} elevation={5}>
      <CardHeader
        title={training.fields.provider.fields.name}
        subheader={training.fields.name}
        titleTypographyProps={{
          letterSpacing: 10,
          fontWeight: "fontWeightBold",
        }}
      />

      <CardContent className={classes.cardContent}>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <Divider className={classes.divider} light />
          <Typography
            className={classes.description}
            color="textSecondary"
            gutterBottom
          >
            {training.fields.trainingDescription}
          </Typography>
        </div>
        <ClassificationList
          training={training}
          provider={provider}
          descriptor={descriptor}
        />
      </CardContent>
    </Card>
  );
};

export default TrainingCard;
