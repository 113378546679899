import { ContentfulCollection } from "contentful";

import {
  IProviderAnnotation,
  ITrainingAnnotation,
} from "../@types/generated/contentful";

export interface IFilterFields {
  [index: string]: Set<string>;
}

export enum ContentTypeEntityEnum {
  Provider = "provider",
  Training = "training",
}
export type ContentTypeEntity =
  | ContentTypeEntityEnum.Provider
  | ContentTypeEntityEnum.Training;

export type IFilterDescriptor = {
  [index in ContentTypeEntity]: IFilterFields;
};

export interface ITypeAnnotations {
  provider: IProviderAnnotation;
  training: ITrainingAnnotation;
}

export interface MyEntryCollection<T> extends ContentfulCollection<T> {
  errors?: Array<any>;
  includes?: any;
  stringifySafe(replacer?: any, space?: any): string;
}

export type ContentTypeDescriptor = {
  [index in ContentTypeEntity]: IContentTypeFields[];
};

export interface IContentTypeFields {
  name: string;
  id: string;
  values: string[];
}
