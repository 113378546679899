import React from "react";

import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

const layoutStyles = makeStyles(() => ({
  main: {
    height: 100,
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(180deg, #F2F7FF 0%, #BED8FE 300%)",
    display: "flex",
  },
  gridContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
  },
  gridContainerItem: {
    paddingRight: 30,
    display: "flex",
    justifyContent: "flex-end",
  },
  headerCaption: {
    paddingLeft: "20px",
    alignSelf: "center",
    color: "#082753",
    fontSize: "1.8em",
    fontWeight: 700,
  },
}));

interface IProps {
  onFilterButtonClick(): void;
  numberOfActiveFilters: number;
}
export function Header({ onFilterButtonClick, numberOfActiveFilters }: IProps) {
  const classes = layoutStyles();
  return (
    <Paper elevation={5} className={classes.main}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={6}>
          <Typography variant="h6" className={classes.headerCaption}>
            Browse Trainings
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.gridContainerItem}>
          <Button
            startIcon={<FilterListIcon />}
            size="medium"
            variant={"contained"}
            color="primary"
            onClick={onFilterButtonClick}
            id="header-filter-button"
          >
            {numberOfActiveFilters === 0
              ? `Filter`
              : `Filter (${numberOfActiveFilters})`}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
