import React, { useState } from "react";

import {
  Chip,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { IContentTypeFields, IFilterFields } from "../../lib/types";

import InfoPopover from "./InfoPopover";

const filterHandleStyles = makeStyles({
  titleRow: {
    border: "none",
    borderStyle: "hidden",
  },
  filterSubSectionHeader: {
    fontWeight: 700,
  },
  chip: {
    margin: 2,
  },
  cell: {
    border: "none",
    borderStyle: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cellChips: {
    border: "none",
  },
  infoIcon: {
    cursor: "pointer",
  },
});

interface IProps {
  titlePrefix?: string;
  selectedValues: IFilterFields;
  field: IContentTypeFields;
  fieldInfoContent?: string;
  handleClick(id: string, value: string): void;
}

const FilterItem = ({
  titlePrefix = "",
  field,
  handleClick,
  selectedValues,
  fieldInfoContent,
}: IProps) => {
  const classes = filterHandleStyles();

  const [infoAnchorEl, setInfoAnchorEl] = useState<SVGSVGElement | null>(null);

  const showPopover = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setInfoAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setInfoAnchorEl(null);
  };

  return (
    <>
      <TableRow className={classes.titleRow}>
        <TableCell className={classes.cell}>
          <Typography variant="h6" className={classes.filterSubSectionHeader}>
            {`${titlePrefix}${field.name}`}
          </Typography>
          <InfoIcon
            className={classes.infoIcon}
            onMouseEnter={showPopover}
            onMouseLeave={closePopover}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.cellChips}>
          {field.values.map((value) => (
            <Chip
              className={classes.chip}
              label={value}
              onClick={() => handleClick(field.id, value)}
              key={value}
              color={
                selectedValues[field.id]?.has(value) ? "primary" : "secondary"
              }
            />
          ))}
        </TableCell>
      </TableRow>
      {fieldInfoContent && (
        <InfoPopover
          handleClose={closePopover}
          content={fieldInfoContent}
          anchorElement={infoAnchorEl}
        />
      )}
    </>
  );
};

export default FilterItem;
