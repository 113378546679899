import { ContentType, createClient } from "contentful";

import {
  IProviderAnnotation,
  ITraining,
  ITrainingAnnotation,
  ITrainingProvider,
} from "../@types/generated/contentful";

import {
  IContentTypeFields,
  IFilterDescriptor,
  ITypeAnnotations,
  MyEntryCollection,
} from "./types";

const client = createClient({
  space: "x40775eoj29n",
  accessToken: "AXdmS5kbwnvlyfKM7Hw364OjiH8IZWe44npJr3sFhgc",
});

export async function getAllTrainings() {
  // @ts-ignore
  return (await client.getEntries<ITraining>({
    content_type: "training",
    limit: "1000",
  })) as MyEntryCollection<ITraining>;
}

export async function getAllProviders() {
  // @ts-ignore
  return (await client.getEntries<ITrainingProvider>({
    content_type: "trainingProvider",
    limit: "1000",
  })) as MyEntryCollection<ITrainingProvider>;
}

export async function getFirstTypeAnnotations() {
  // @ts-ignore
  const t = (await client.getEntries<ITrainingAnnotation>({
    content_type: "trainingAnnotation",
  })) as MyEntryCollection<ITrainingAnnotation>;
  // @ts-ignore
  const p = (await client.getEntries<IProviderAnnotation>({
    content_type: "providerAnnotation",
  })) as MyEntryCollection<IProviderAnnotation>;

  return { training: t.items[0], provider: p.items[0] } as ITypeAnnotations;
}

export async function getFilteredTrainings(selection: IFilterDescriptor) {
  const query: { [index: string]: string } = {
    content_type: "training",
    limit: "1000",
  };
  //Training
  Object.keys(selection.training).forEach((key) => {
    const field: string = `fields.${key}[in]`;
    if (selection.training[key].size > 0) {
      query[field] = Array.from(selection.training[key]).join(",");
    }
  });
  //Provider
  if (Object.keys(selection.provider).length > 0) {
    query["fields.provider.sys.contentType.sys.id"] = "trainingProvider";
    Object.keys(selection.provider).forEach((key) => {
      if (selection.provider[key].size > 0) {
        const field: string = `fields.provider.fields.${key}[in]`;
        query[field] = Array.from(selection.provider[key]).join(",");
      }
    });
  }

  // @ts-ignore
  return (await client.getEntries<ITraining>(
    query
  )) as MyEntryCollection<ITraining>;
}

export async function getContentTypeDescriptor() {
  const provider = await client.getContentType("trainingProvider");
  const training = await client.getContentType("training");
  return {
    provider: getAvailableFieldsForContentType(provider),
    training: getAvailableFieldsForContentType(training),
  };
}

function getAvailableFieldsForContentType(
  type: ContentType
): IContentTypeFields[] {
  return type.fields
    .filter((x) => x.items?.type === "Symbol")
    .map((x) =>
      x.items!.validations.map((v) => ({
        name: x.name,
        id: x.id,
        values: v.in || [],
      }))
    )
    .flat();
}

export interface IContentfulResult<T> {
  data: T;
  errors: any;
}

export function makeRequest<T>(query: string): Promise<IContentfulResult<T>> {
  return fetch(
    `https://graphql.contentful.com/content/v1/spaces/x40775eoj29n/environments/master`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authenticate the request
        Authorization: "Bearer AXdmS5kbwnvlyfKM7Hw364OjiH8IZWe44npJr3sFhgc",
      },
      // send the GraphQL query
      body: JSON.stringify({ query }),
    }
  ).then((response) => response.json());
}
