import React, { useState } from "react";

import { Steps } from "intro.js-react";
import "intro.js/introjs.css";

export interface IProps {
  onIntroDone(): void;
  showFilters(show: boolean): void;
}

export const IntroTour = ({
  onIntroDone,
  showFilters,
}: IProps): JSX.Element => {
  const [isEnabled, setEnabled] = useState(true);

  const steps = [
    {
      element: "#filters",
      intro: "Welcome. Filtering is essential to finding the right trainings.",
    },
    {
      element: "#filters-always-visible",
      intro:
        "As a first step, choose from any of these options to narrow down the results. Filters are applied as you select them",
    },
    {
      element: "#filters-more",
      intro: "Are you looking for additional filters? Click here ",
    },
    {
      element: "#filters-clear",
      intro:
        "You can either deselect individual filters, or clear all at once by clicking this button",
    },
    {
      element: "#filters-close",
      intro: "You can close the filter panel by clicking the 'x'",
    },
    {
      element: "#header-filter-button",
      intro:
        "You can always bring the filter panel back by clicking the 'Filter' button",
    },
  ];

  return (
    <Steps
      enabled={isEnabled}
      steps={steps}
      initialStep={0}
      onExit={() => {
        setEnabled(false);
        showFilters(true);
        setTimeout(onIntroDone, 1);
      }}
      options={{ hideNext: false }}
      onBeforeChange={(nextStepIndex) => {
        if (nextStepIndex === 5) {
          showFilters(false);
        }
      }}
    />
  );
};
