import React, { useCallback, useMemo, useState } from "react";

import { Button, Collapse, Divider, Grid, makeStyles } from "@material-ui/core";

import {
  ITraining,
  ITrainingProvider,
} from "../../@types/generated/contentful";
import {
  ContentTypeEntity,
  ContentTypeEntityEnum,
  ContentTypeDescriptor,
} from "../../lib/types";

import ClassificationItem from "./ClassificationItem";

interface IProps {
  training: ITraining;
  provider: ITrainingProvider;
  descriptor: ContentTypeDescriptor;
}

const cardStyles = makeStyles(() => ({
  classificationList: {
    backgroundColor: "#F2F7FF",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    marginBottom: -24,
  },
  dividerContainer: {
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    width: "80%",
  },
}));

const ClassificationList = ({ training, provider, descriptor }: IProps) => {
  const classes = cardStyles();
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  const getLabelById = useCallback((id: string, type: ContentTypeEntity) => {
    return descriptor[type].find((x) => x.id === id)!.name;
  }, []);

  return useMemo(
    () => (
      <Grid container spacing={2} className={classes.classificationList}>
        <ClassificationItem
          title={getLabelById("language", ContentTypeEntityEnum.Training)}
          values={training.fields.language}
        />
        <ClassificationItem
          title={getLabelById("focus", ContentTypeEntityEnum.Training)}
          values={training.fields.focus}
        />

        <ClassificationItem
          title={getLabelById("format", ContentTypeEntityEnum.Training)}
          values={training.fields.format}
        />
        <ClassificationItem
          title={getLabelById("price", ContentTypeEntityEnum.Training)}
          values={training.fields.price}
        />
        <ClassificationItem
          title="Certification"
          values={training.fields.certification ? ["Yes"] : ["No"]}
        />
        <ClassificationItem
          title="Link To Training Website"
          values={
            training.fields.linkToTheTrainingWebsiteFocalPoint
              ? [training.fields.linkToTheTrainingWebsiteFocalPoint]
              : []
          }
          isLink
          hideWhenNoValues
        />

        <Grid item xs={12}>
          {!expanded && (
            <Button
              color="primary"
              style={{ width: "100%" }}
              onClick={toggleExpanded}
            >
              Additional Information
            </Button>
          )}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.dividerContainer}>
                <Divider className={classes.divider} light />
              </Grid>
              <ClassificationItem
                title={getLabelById(
                  "depthOfTraining",
                  ContentTypeEntityEnum.Training
                )}
                values={training.fields.depthOfTraining}
              />
              <ClassificationItem
                title={getLabelById("format", ContentTypeEntityEnum.Training)}
                values={training.fields.format}
              />
              <ClassificationItem
                title={getLabelById(
                  "frequency",
                  ContentTypeEntityEnum.Training
                )}
                values={training.fields.frequency}
              />
              <ClassificationItem
                title={getLabelById("duration", ContentTypeEntityEnum.Training)}
                values={training.fields.duration}
              />
              <ClassificationItem
                title={getLabelById(
                  "audienceType",
                  ContentTypeEntityEnum.Training
                )}
                values={training.fields.audienceType}
              />
              <ClassificationItem
                title={`Provider ${getLabelById(
                  "type",
                  ContentTypeEntityEnum.Provider
                )}`}
                values={provider.fields.type}
              />
              <ClassificationItem
                title={`Provider ${getLabelById(
                  "location",
                  ContentTypeEntityEnum.Provider
                )}`}
                values={provider.fields.location}
              />
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    ),
    [expanded]
  );
};

export default ClassificationList;
