import React from "react";

import { Grid, Link, makeStyles, Typography } from "@material-ui/core";

interface IClassificationItem {
  title: string;
  values?: string[];
  isLink?: boolean;
  hideWhenNoValues?: boolean;
}

const itemStyles = makeStyles({
  title: {
    fontSize: 12,
    textTransform: "uppercase",
  },
  secondary: {
    fontSize: 12,
  },
});

const ClassificationItem = ({
  title,
  values,
  isLink,
  hideWhenNoValues,
}: IClassificationItem) => {
  const classes = itemStyles();

  const hideClassificationItem =
    (values || []).length === 0 && hideWhenNoValues;

  return (
    <>
      {!hideClassificationItem && (
        <Grid item xs={6} sm={6} lg={6}>
          <Typography className={classes.title} gutterBottom>
            {title}
          </Typography>
          <Typography
            className={classes.secondary}
            color="textSecondary"
            gutterBottom
          >
            {isLink && (
              <Link target="_blank" href={values?.join("")}>
                Click Here
              </Link>
            )}
            {!isLink && <>{values?.join(", ")}</>}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default ClassificationItem;
