import React from "react";

import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

interface IProps {
  handleClose(): void;
  content: string;
  anchorElement: SVGSVGElement | null;
}

export default function InfoPopover({
  handleClose,
  content,
  anchorElement,
}: IProps) {
  const classes = useStyles();

  const open = Boolean(anchorElement);

  return (
    <Popover
      id="mouse-over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClose}
      disableRestoreFocus
      disableScrollLock
    >
      <Typography>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Typography>
    </Popover>
  );
}
