import React, { ReactNode } from "react";

import { Grid, makeStyles, Typography } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  caption: {
    paddingLeft: "20px",
    alignSelf: "center",
    color: "#AAAAAA",
    fontSize: "1.8em",
    fontWeight: 700,
  },
  container: {
    justifyContent: "center",
  },
}));

interface IProps {
  children: ReactNode;
}

const HomeMessage = ({ children }: IProps) => {
  const classes = styles();
  return (
    <Grid container className={classes.container}>
      <Grid item>
        <Typography className={classes.caption}>{children}</Typography>
      </Grid>
    </Grid>
  );
};

export default HomeMessage;
